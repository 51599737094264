@import "../../styles/mixins.scss";

.container {
    background-color: white;
    height: 87vh;
    @include flex-center("");
    flex-direction: column;
    img {
        height: 40%;
    }
    p {
        text-align: center;
        font-size: 20px;
        font-weight: 500;
        margin: 30px auto;
    }
}
