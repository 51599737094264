@font-face {
    font-family: "MarkPro";
    src: local("MarkPro"), url("./theme/Fonts/MarkPro.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "MarkPro";
    src: local("MarkPro") url("./theme/Fonts/MarkProMedium.ttf") format("truetype");
    font-weight: 500;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "MarkPro";
}

.url {
    color: #0430c0;
    text-decoration: underline;
    cursor: pointer;
}

.uid {
    word-break: break-all;
}

.error_message {
    color: #f94054;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    margin: -5px 14px 0;
}

.MuiTooltip-tooltip {
    font-size: 12px !important;
    color: #101941 !important;
    background-color: white !important;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.12) !important;
    font-weight: 400 !important;
}
.MuiTooltip-tooltip .MuiTooltip-arrow {
    color: #fff;
}

.login_error_message {
    color: #f94054;
    font-size: 12px;
    font-weight: 500;
}

.login_success_message {
    color: #12d377;
    font-size: 12px;
    font-weight: 500;
}

.otp_container fieldset {
    border-radius: 5px;
    border: 2px solid #f94054 !important;
    outline: none;
}

.toggle_disable .MuiSwitch-input {
    cursor: default;
}

.actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.actions img {
    height: 18px;
}
.headerless_actions_container {
    display: inline-block;
    width: 100%;
}

.headerless_actions_container .action_section img {
    height: 18px;
}

.headerless_actions_container .action_section p {
    font-size: 12px;
}

.headerless_actions_container .action_section:hover {
    font-weight: 500;
    background-color: #e4e4e48a;
}

.headerless_actions_container .action_section {
    display: flex;
    justify-content: left;
    align-items: center;
    cursor: pointer;
    gap: 10px;
    margin-bottom: 5px;
    width: 100%;
    padding: 2px;
}

.headerless_actions_container .action_section:last-child {
    margin-bottom: 0;
}

.MuiFormHelperText-root {
    margin-left: 4px !important;
}
