@import "../../styles/mixins.scss";

.login_wrapper {
    background: url("https://d3orevttu06iqr.cloudfront.net/evlinq/login_bg.png");
    height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 65%;
    position: relative;

    &__section {
        width: 350px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &__logo {
            height: 45px;
            @include flex-center("");
            @include space(margin, bottom, 30px, 0px);
        }

        &__description {
            text-align: center;
            display: inline-block;
            width: 100%;

            span {
                display: inline-block;
                color: #fff;
                font-weight: 500;
                @include font-size(20);
            }
        }
    }
}
