@import "../../styles/mixins.scss";

.menu_drawer {
    &__full_logo {
        height: 25px;

        @include breakpoint(desktop-sm) {
            height: 25px;
        }

        @include breakpoint(desktop-md) {
            height: 25px;
        }

        @include breakpoint(desktop-lg) {
            height: 30px;
        }
    }

    &__small_logo {
        height: 26.8px;

        @include breakpoint(desktop-sm) {
            height: 26.8px;
        }

        @include breakpoint(desktop-md) {
            height: 24px;
        }

        @include breakpoint(desktop-lg) {
            height: 32px;
        }
    }
}

.parent_nav {
    border-color: #fc441e !important;

    .nav_buttons {
        color: #fc441e;
        // span {
        //     // @include filter-primary-orange;
        //     color: #fc441e;
        //     font-weight: 500;
        // }
        p {
            border: none;
            color: #70758d;
            font-size: 12px !important;

            @include breakpoint(desktop-sm) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-md) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-lg) {
                font-size: 14px !important;
            }
        }

        span {
            border: none;
            color: #101941;
            font-size: 12px !important;

            @include breakpoint(desktop-sm) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-md) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-lg) {
                font-size: 14px !important;
            }
        }
        &__active {
            color: #fc441e;
            p {
                color: #fc441e;
                font-weight: 500;
                font-size: 12px !important;

                @include breakpoint(desktop-sm) {
                    font-size: 12px !important;
                }

                @include breakpoint(desktop-md) {
                    font-size: 12px !important;
                }

                @include breakpoint(desktop-lg) {
                    font-size: 14px !important;
                }
            }

            span {
                color: #fc441e;
                font-weight: 500;
                font-size: 12px !important;

                @include breakpoint(desktop-sm) {
                    font-size: 12px !important;
                }

                @include breakpoint(desktop-md) {
                    font-size: 12px !important;
                }

                @include breakpoint(desktop-lg) {
                    font-size: 14px !important;
                }
            }
        }
    }

    .nav_buttons_icons {
        img {
            @include filter-primary-orange;
        }
    }

    // &:hover {
    //     //    @include filter-primary-orange;
    // }
}

.parent_nav_submenu {
    color: #101941;
    border: none !important;

    // span {
    //     border: none;
    //     color: #101941;
    //     font-weight: 600;
    // }
    &__active {
        color: #101941;
        border: none !important;
        p {
            border: none;
            color: #101941;
            font-weight: 600;
            font-size: 12px !important;

            @include breakpoint(desktop-sm) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-md) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-lg) {
                font-size: 14px !important;
            }
        }

        span {
            border: none;
            color: #101941;
            font-weight: 600;
            font-size: 12px !important;

            @include breakpoint(desktop-sm) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-md) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-lg) {
                font-size: 14px !important;
            }
        }

        img {
            @include filter-primary-blue;
        }
    }
    p {
        border: none;
        color: #70758d;
        font-size: 12px !important;

        @include breakpoint(desktop-sm) {
            font-size: 12px !important;
        }

        @include breakpoint(desktop-md) {
            font-size: 12px !important;
        }

        @include breakpoint(desktop-lg) {
            font-size: 14px !important;
        }
    }

    span {
        border: none;
        color: #101941;

        @include breakpoint(desktop-sm) {
            font-size: 12px !important;
        }

        @include breakpoint(desktop-md) {
            font-size: 12px !important;
        }

        @include breakpoint(desktop-lg) {
            font-size: 14px !important;
        }
    }

    &:hover {
        color: #101941;
        // @include filter-primary-orange;
    }
}

.selected {
    border-color: #fc441e !important;

    .nav_buttons {
        color: #fc441e;

        // span {
        //     //    @include filter-primary-orange;

        //     color: #fc441e;
        //     font-weight: 500;
        // }
        p {
            color: #fc441e;
            font-weight: 500;
            font-size: 12px !important;

            @include breakpoint(desktop-sm) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-md) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-lg) {
                font-size: 14px !important;
            }
        }

        span {
            color: #fc441e;
            font-weight: 500;
            font-size: 12px !important;

            @include breakpoint(desktop-sm) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-md) {
                font-size: 12px !important;
            }

            @include breakpoint(desktop-lg) {
                font-size: 14px !important;
            }
        }
    }

    .nav_buttons_icons {
        img {
            // @include filter-primary-orange;
            @include filter-primary-orange;
        }
    }

    // &:hover {
    //     //    @include filter-primary-orange;
    // }
}

.submenu_navbuttons {
    p {
        font-size: 12px !important;
        color: #70758d;

        @include breakpoint(desktop-sm) {
            font-size: 12px !important;
        }

        @include breakpoint(desktop-md) {
            font-size: 12px !important;
        }

        @include breakpoint(desktop-lg) {
            font-size: 14px !important;
        }
    }

    span {
        font-size: 12px !important;

        @include breakpoint(desktop-sm) {
            font-size: 12px !important;
        }

        @include breakpoint(desktop-md) {
            font-size: 12px !important;
        }

        @include breakpoint(desktop-lg) {
            font-size: 14px !important;
        }
    }

    &:last-child {
        margin-bottom: 5px;
    }
}
