@import "../../styles/mixins.scss";

.login_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35%;
    background-color: #f4f4f7;
}

.form_wrapper {
    width: 400px;
    height: 41.5vh;
    box-shadow: 0px 0px 0px 0px #fff;
    @include space(padding, all, 40px, 0px);
    &__get_help {
        text-decoration: underline;
        color: #fc441e;
        cursor: pointer;
    }
}
