//Flex center items
@mixin flex-center($important) {
    @if ($important == "!important") {
        display: flex !important;
        align-items: center !important;
        justify-content: center !important;
    } @else {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

//Flex right items
@mixin flex-right {
    display: flex;
    align-items: center;
    justify-content: right;
}

//Flex left items
@mixin flex-left {
    display: flex;
    align-items: center;
    justify-content: left;
}

//Flex space between items
@mixin flex-space-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

//Flex space evenly items
@mixin flex-space-evenly {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

//Flex space around items
@mixin flex-space-around {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@mixin flex {
    display: flex;
    align-items: center;
}

/*Font size*/
@function calculateRem($size) {
    $remSize: calc($size / 16);
    @return $remSize * 1rem;
}

@mixin font-size($size) {
    font-size: calculateRem($size);
}

@mixin font-sizeImp($sizeimp) {
    font-size: calculateRem($sizeimp) !important;
}

@mixin filter-primary-orange {
    filter: invert(39%) sepia(89%) saturate(1967%) hue-rotate(326deg) brightness(93%) contrast(110%);
}

@mixin filter-primary-blue {
    filter: invert(1%) sepia(50%) saturate(6655%) hue-rotate(232deg) brightness(40%) contrast(240%);
}

@mixin filter-white {
    filter: invert(100%) sepia(170%) saturate(0%) hue-rotate(360deg) brightness(102%) contrast(102%);
}

//For margin and padding
@mixin space($type, $direction, $first, $second) {
    @if $type ==padding {
        @if $direction ==all {
            padding: $first;
        } @else if $direction ==top-bottom {
            padding-top: $first;
            padding-bottom: $second;
        } @else if $direction ==left-right {
            padding-left: $first;
            padding-right: $second;
        } @else if $direction ==top {
            padding-top: $first;
        } @else if $direction ==bottom {
            padding-bottom: $first;
        } @else if $direction ==left {
            padding-left: $first;
        } @else if $direction ==right {
            padding-right: $first;
        } @else if ($direction ==top AND $direction ==right AND $direction ==bottom AND $direction ==left) {
            padding: $first $second $first $second;
        }
    }

    @if $type ==margin {
        @if $direction ==all {
            margin: $first;
        } @else if $direction ==top-bottom {
            margin-top: $first;
            margin-bottom: $second;
        } @else if $direction ==left-right {
            margin-left: $first;
            margin-right: $second;
        } @else if $direction ==top {
            margin-top: $first;
        } @else if $direction ==bottom {
            margin-bottom: $first;
        } @else if $direction ==left {
            margin-left: $first;
        } @else if $direction ==right {
            margin-right: $first;
        }
    }
}

@mixin breakpoint($point) {
    @if $point ==md {
        @media (min-width: 900px) and (max-width: 1199px) {
            @content;
        }
    } @else if $point ==lg {
        @media (min-width: 1200px) and (max-width: 1279px) {
            @content;
        }
    } @else if $point ==desktop-sm {
        @media (min-width: 1280px) and (max-width: 1535.9px) {
            @content;
        }
    } @else if $point ==desktop-md {
        @media (min-width: 1536px) and (max-width: 1919.9px) {
            @content;
        }
    } @else if $point ==desktop-lg {
        @media (min-width: 1920px) {
            @content;
        }
    }
}
