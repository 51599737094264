.input {
    border-radius: 8px;
    cursor: default;
    input {
        padding: "12px";
    }
    &__disabled {
        border-radius: 8px;
        input {
            cursor: not-allowed !important;
        }
    }

    &__error_message {
        margin: 3px 0px 0px 0px !important;
    }
}

.input_text {
    margin: 10px 0px 0px 0px !important;
    font-size: 10px !important;
    color: #404767 !important;
    font-family: MarkPro !important;
}
