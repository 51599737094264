@import "../../styles/mixins.scss";

.minidrawer {
    &__open_icon_container {
        position: absolute;
        @include flex-center("");
        left: 225px;
        top: 13px;

        @include breakpoint(desktop-sm) {
            left: 225px;
            top: 13px;
        }

        @include breakpoint(desktop-md) {
            left: 274px;
            top: 13px;
        }

        @include breakpoint(desktop-lg) {
            left: 348px;
            top: 13px;
        }
    }

    &__close_icon_container {
        position: absolute;
        @include flex-center("");
        left: 43px;
        top: 13px;

        @include breakpoint(desktop-sm) {
            left: 81px;
            top: 13px;
        }

        @include breakpoint(desktop-md) {
            left: 71px;

            top: 13px;
        }

        @include breakpoint(desktop-lg) {
            left: 93px;
            top: 13px;
        }
    }

    &__arrow_icon {
        cursor: pointer;

        img {
            height: 26.8px;
            width: 26.8px;

            @include breakpoint(desktop-sm) {
                height: 26.8px;
                width: 26.8px;
            }

            @include breakpoint(desktop-md) {
                height: 24px;
                width: 24px;
            }

            @include breakpoint(desktop-lg) {
                height: 30px;
                width: 30px;
            }
        }
    }
}
