.avatar_container {
    display: flex;
    justify-content: center;
    align-items: center;

    &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 40px;
        height: 40px;

        &__icon {
            width: 40px;
            height: 40px;
        }

        &__edit_icon {
            position: absolute;
            width: 14px;
            height: 14px;
            right: 0;
            bottom: 0;
        }
        &:hover {
            scale: 1.05;
            transition: 0.125s ease-in;
        }
    }
}
